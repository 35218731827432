import React, { useState, useEffect } from 'react'
import './NavMenu.css'
import FormSearch from './../../composant/FormSearch';
import SidebarProfile from '../SidebarProfile/SidebarPorfile'
import FloatingCartButton from '../FloatingCartButton';
import { getCarrinho } from '../../redux/cartReducer'
import { getUsersLists } from '../../redux/userReducer'
import logo from './../../assets/images/estilizado.png'
import { useSelector, useDispatch } from "react-redux"
import { createPortal } from "react-dom"
import Cart from "./../Cart"
import Login from '../../pages/Login/Login';
import RegisterUser from '../../pages/RegisterUser'
import { isEmpty } from '../Utils';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export default function NavMenu() {
    const navigate = useNavigate()
    const goHome = () => {navigate ("/")}
    const [showModalCart, setShowModalCart] = useState(false)
    const [showModalProfil, setShowModalProfil] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);

    const [carousselIndex, setCarousselIndex] = useState("");
    const [titreImagem, setTitreImagem] = useState("");
    let menu = document.querySelector('#menu-bars');
    let navMenu = document.querySelector('.nav-menu');


    const sacola = useSelector(state => state.cartReducer)
    const usuario = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn')
        goHome()
        setShowModalProfil(false)
    }
    

    if (sacola === undefined) {
        dispatch(getCarrinho())
    }
    if (usuario.items === undefined) {
        dispatch(getUsersLists())
    }


    const idUser = localStorage.getItem('idUser')
    
    const userConnect = usuario.items && usuario.items.find(el => el.id_usuario === idUser)
    console.log(usuario.items)

    const toggleModal = () => {
        setShowModal(!showModal);

        if (showModal == false) {
            menu.classList.add('fa-times');

        }
        else {
            menu.classList.remove('fa-times');

        }
    };
    const toggleModalLogin = () => {
        setShowLogin(!showLogin)
    }
    const toggleModalRegister = () => {
        setShowRegister(!showRegister)
    }
    const toggleModalProfil = () => {
        setShowModalProfil(!showModalProfil)
        console.log(showModalProfil)
    }
    const selectMenu = () => {
        setShowModal(!showModal);

        if (menu.classList.contains("fa-times")) {
            menu.classList.remove('fa-times');
            navMenu.classList.remove('active');

        }
        else {
            menu.classList.add('fa-times');
            navMenu.classList.add('active');
        }
    }


    const removeSelectMenu = () => {
        //ativar o item do menu selecionado
        const buttons = document.querySelectorAll('.nav-menu a')


        let url = window.location.href;
        buttons.forEach((button) => {

            if (button.href === url) {
                button.classList.add("active")
            }
            else if (button.href != url) {
                button.classList.remove("active")

            }
        })
    }
    useEffect(() => {



        removeSelectMenu()

    }, [removeSelectMenu]);

    window.onscroll=()=>{
        setShowModalProfil(false)
    }

    /*window.onscroll=()=>{
        menu.classList.remove('fa-times');
        navMenu.classList.remove('active')
    }
    document.addEventListener("click", fecharNavBar)

        function fecharNavBar(){
            if(menu.classList.contains("fa-times")){
                menu.classList.remove('fa-times');
                navMenu.classList.remove('active')
             console.log("sim")
            }
           
      
        }*/

    const selectSearch = () => {
        document.querySelector('#search-form').classList.add('active');
    }
    const selectClose = () => {
        document.querySelector('#search-form').classList.remove('active');
    }
    return (
        <>
            <header>
                <a href="#" className='logo'> <img src={logo} alt="" /></a>
                <nav className='nav-menu'>
                    <a href={`${process.env.PUBLIC_URL}/`} className='active'>Início</a>
                    <a href="/loja-congo">Loja</a>
                    <a href="/redirect_cardapio/">Cardapio</a>
                    <a href="/vegFest/"> VegFest 2024</a>
                    <a href="/receitas">Receitas</a>
                    <a href="/contato">Contato</a>
                    <a href="/sobre">Sobre</a>
                    <a href="/blog">Blog</a>
                    <a href="/#order">Orçamento/festa/Eventos </a>
                </nav>
                <FormSearch />
                <div className="icons">
                    {isLoggedIn ? (<div className='icons'>
                        <i className="fas fa-bars" id='menu-bars' onClick={toggleModal}></i>

                        <i className="fas fa-search" id='search-icon' onClick={selectSearch}></i>
                       

                        <button class="notification" onClick={() => {setShowModalCart(!showModalCart)
                        setShowModalProfil(false)}}>
                            <i className='fas fa-shopping-cart' ></i>
                            <span className="num">{(sacola.items) && sacola.items.length}</span>
                        </button>

                        {showModalCart &&
                            createPortal(
                                <Cart sacola={sacola.items} onClose={() => setShowModalCart(false)} />,
                                document.body
                            )}
                        <i className="fa-solid fa-user" onClick={toggleModalProfil}></i>
                        

                    </div>) : (<div className="icons" >
                    <i className="fas fa-bars" id='menu-bars' onClick={toggleModal}></i>
                    <i className="fas fa-search" id='search-icon' onClick={selectSearch}></i>
                        <span onClick={toggleModalLogin}> Entrar </span>
                        {showLogin && <Login onClose={() => setShowLogin(false)} />}
                        <span onClick={toggleModalRegister}> Cadastrar</span>
                        {showRegister && <RegisterUser onClose={() => setShowRegister(false)} />}
                    </div>)}
                    {showModalProfil && <SidebarProfile nomeUser={userConnect.nome} handleLogout={handleLogout} toggleModalProfil={toggleModalProfil}/>}
                </div>
            </header>
            <form action="" id="search-form">
                <input type="search" name="" placeholder='pesquisar aqui ' id="search-box" />
                <label htmlFor="search-box" className='fas fa-search'></label>
                <i className="fas fa-times" id="close" onClick={selectClose}></i>
            </form>
            {showModal && (<div>
                <nav className='menu-responsive'>
                    <a href="/" className='active' onClick={toggleModal} >Início</a>
                    <a href="/loja-congo" onClick={toggleModal} >Loja</a>
                    <a href="/redirect_cardapio/" onClick={toggleModal} >Cardapio</a>
                    <a href="/receitas" onClick={toggleModal} >Receitas</a>
                    <a href="/contato" onClick={toggleModal} >Contato</a>
                    <a href="/sobre" onClick={toggleModal} >Sobre</a>
                    <a href="/noticias" onClick={toggleModal} >Notícias</a>
                    <a href="/#order" onClick={toggleModal} >Orçamento/festa/Eventos </a>
                </nav>
            </div>)}

        </>
    )
}
