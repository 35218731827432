import React,{useEffect,useState} from 'react'
import './Slider.css'
import sliderData from '../../data/sliderData';
import leftChevron from './../../assets/images/SVG/left-arrow.svg'
import rightChevron from './../../assets/images/SVG/right-arrow.svg'
import { useSelector, useDispatch } from "react-redux"
import { getSlidersLists } from '../../redux/sliderReducer';
export default function Slider() {
    const[sliderIndex,setSilderIndex]=useState(1);
    const dispatch = useDispatch()
    const slider = useSelector(state => state.sliderReducer)

    
    const sliderUnicos = [];
    let content;
    let titre;

    if (!slider.items && !slider.loading && !slider.error) {
        dispatch(getSlidersLists())
    }
    else if (slider.loading) {
        //content = <img src={spinner} alt='spinning loader'/>
    }
    else if (slider.error) {
        content = <p> An error has occured</p>
    }
    else if (slider.items) {
        Object.values(slider.items).forEach((item) => {
            var duplicated = sliderUnicos.findIndex(redItem => {
                return item.titre_slider === redItem.titre_slider;
            }) > -1
            if (!duplicated) {
                sliderUnicos.push(item);
            }

        });
        
        if(sliderUnicos){
           
            function pegarTitre(element){
                return sliderIndex===element.id_slider
              }
              titre = sliderUnicos.find(pegarTitre)
        }
    }
    console.log(sliderUnicos)
    console.log(titre)
    console.log(slider.items)

    function toggleImage(indexPayload){
        //let newState;
        //if(indexPayload+sliderIndex>sliderData.length){newState=1    }
        //else if(indexPayload + sliderIndex<1){
           // newState=sliderData.length
        //}
        //else{
       //     newState=indexPayload+sliderIndex
        //}
        //setSilderIndex(newState)
        
        
        setSilderIndex(state=>{
           
            if(indexPayload+state>sliderUnicos.length){return 1}
        else if(indexPayload + state<1){
            return sliderUnicos.length
        }
        else{
          return  state +indexPayload
        }
        })
    }
    console.log(sliderIndex)

useEffect(()=>{
const intervalID = setInterval(()=>toggleImage(1),5000)
return()=>clearInterval(intervalID)
},[])
  return (
    <>
      <section className='home' id='home'>

<div className="home-slider">

    <div className="wrapper">
    
            <div className="slide">
        
                <div className="content">
                    <span>CONHEÇA NOSSOS PRATOS</span>
                    <h3>{ titre && titre.titre_slider}</h3>
                    <p>{  titre && titre.description_slider}</p>
                        <a href="#" className='btn'>Saiba mais</a>
                </div>
                <div className="image">
                    <img src={  titre && "https://congolinaria.com.br/" + titre.imagem_slider} alt="previous image" />
                </div>
                <button  onClick={()=>toggleImage(-1)}className="navigation-button prev-button-slider">
                    <img src={leftChevron} alt="" />
                    </button>
                    <button onClick={()=>toggleImage(1)} className="navigation-button next-button-slider">
                    <img src={rightChevron} alt="next image" />
                    </button>

            </div>
        
        
    </div>
</div>
</section>
    </>
  )
}
